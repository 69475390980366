var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "base-container"
  }, [_vm._m(0), _c('el-table', {
    staticClass: "body",
    attrs: {
      "data": _vm.list
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "直播活动",
      "width": "500px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "base-info"
        }, [_c('el-image', {
          staticClass: "cover",
          attrs: {
            "fit": "cover",
            "src": scope.row.shareImg
          }
        }), _c('div', {
          staticClass: "text"
        }, [_c('p', {
          staticClass: "name"
        }, [_vm._v(" " + _vm._s(scope.row.name)), scope.row.recommendStatus ? _c('span', {
          staticClass: "f-top-badge"
        }, [_vm._v("荐")]) : _vm._e()]), _c('div', {
          staticClass: "sub"
        }, [_c('p', [_vm._v("开播：" + _vm._s(scope.row.startTime))]), _c('p', [_vm._v("结束：" + _vm._s(scope.row.endTime))]), _c('p', [_vm._v(" 主播：" + _vm._s(scope.row.anchorName) + "(" + _vm._s(scope.row.anchorWechat) + ") ")])])])], 1)];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "状态"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('p', {
          class: {
            red: scope.row.liveStatus === 101
          }
        }, [_vm._v(" " + _vm._s(_vm.status[scope.row.liveStatus]) + " ")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "申请人",
      "prop": "applyUserName"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "申请时间",
      "prop": "inDate"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "操作",
      "width": "180px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('el-dropdown', {
          on: {
            "visible-change": function visibleChange(bool) {
              return _vm.getQrCode(scope.row.roomId, bool);
            }
          }
        }, [_c('el-button', {
          attrs: {
            "type": "text"
          }
        }, [_vm._v("二维码")]), _c('el-dropdown-menu', {
          attrs: {
            "slot": "dropdown",
            "disabled": ""
          },
          slot: "dropdown"
        }, [_c('el-dropdown-item', {
          staticClass: "dropdown-qrcode"
        }, [_c('el-image', {
          staticClass: "img",
          attrs: {
            "fit": "cover",
            "src": _vm.curViewQrCode
          }
        }, [!_vm.curViewQrCode ? _c('i', {
          staticClass: "qrcode-loading el-icon-loading",
          attrs: {
            "slot": "error"
          },
          slot: "error"
        }) : _vm._e()]), _c('p', {
          staticClass: "tips"
        }, [_vm._v(" 请使用微信扫描二维码 ")])], 1)], 1)], 1), _c('el-button', {
          staticClass: "mlr10",
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.changeLiveTop(scope.row.id, !scope.row.recommendStatus);
            }
          }
        }, [_vm._v(_vm._s(scope.row.recommendStatus ? "取荐" : "推荐"))]), _c('el-popconfirm', {
          attrs: {
            "title": "确定删除该直播？"
          },
          on: {
            "onConfirm": function onConfirm($event) {
              return _vm.delLive(scope.row.id);
            }
          }
        }, [_c('el-button', {
          attrs: {
            "slot": "reference",
            "type": "text"
          },
          slot: "reference"
        }, [_vm._v("删除")])], 1)];
      }
    }])
  })], 1), _c('el-pagination', {
    attrs: {
      "current-page": _vm.queryData.pageNum,
      "page-sizes": _vm.pageSizes,
      "page-size": _vm.queryData.pageSize,
      "layout": "sizes, prev, pager, next,jumper",
      "total": _vm.total
    },
    on: {
      "size-change": _vm.pageSizeChange,
      "current-change": _vm.pageNumChange,
      "update:currentPage": function updateCurrentPage($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      },
      "update:current-page": function updateCurrentPage($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      }
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header-mol"
  }, [_c('div', {
    staticClass: "info"
  }, [_c('h3', {
    staticClass: "title"
  }, [_vm._v("直播活动")]), _c('p', {
    staticClass: "summary"
  }, [_vm._v(" 当前直播应用接入的是微信小程序直播，受小程序直播限制本页面仅支持查看，如需管理直播请登录微信小程序运营管理后台； ")])])]);

}]

export { render, staticRenderFns }