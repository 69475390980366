<template>
  <div class="base-container">
    <div class="header-mol">
      <div class="info">
        <h3 class="title">直播活动</h3>
        <p class="summary">
          当前直播应用接入的是微信小程序直播，受小程序直播限制本页面仅支持查看，如需管理直播请登录微信小程序运营管理后台；
        </p>
      </div>
    </div>
    <el-table class="body" :data="list">
      <el-table-column label="直播活动" width="500px">
        <template slot-scope="scope">
          <div class="base-info">
            <el-image
              class="cover"
              fit="cover"
              :src="scope.row.shareImg"
            ></el-image>
            <div class="text">
              <p class="name">
                {{ scope.row.name
                }}<span class="f-top-badge" v-if="scope.row.recommendStatus"
                  >荐</span
                >
              </p>
              <div class="sub">
                <p>开播：{{ scope.row.startTime }}</p>
                <p>结束：{{ scope.row.endTime }}</p>
                <p>
                  主播：{{ scope.row.anchorName }}({{ scope.row.anchorWechat }})
                </p>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <p :class="{ red: scope.row.liveStatus === 101 }">
            {{ status[scope.row.liveStatus] }}
          </p>
        </template>
      </el-table-column>
      <el-table-column label="申请人" prop="applyUserName"></el-table-column>
      <el-table-column label="申请时间" prop="inDate"></el-table-column>
      <el-table-column label="操作" width="180px">
        <template slot-scope="scope">
          <el-dropdown
            @visible-change="bool => getQrCode(scope.row.roomId, bool)"
          >
            <el-button type="text">二维码</el-button>
            <el-dropdown-menu slot="dropdown" disabled>
              <el-dropdown-item class="dropdown-qrcode">
                <el-image class="img" fit="cover" :src="curViewQrCode">
                  <i
                    v-if="!curViewQrCode"
                    slot="error"
                    class="qrcode-loading el-icon-loading"
                  ></i>
                </el-image>
                <p class="tips">
                  请使用微信扫描二维码
                </p>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button
            class="mlr10"
            type="text"
            @click="changeLiveTop(scope.row.id, !scope.row.recommendStatus)"
            >{{ scope.row.recommendStatus ? "取荐" : "推荐" }}</el-button
          >

          <el-popconfirm
            title="确定删除该直播？"
            @onConfirm="delLive(scope.row.id)"
          >
            <el-button slot="reference" type="text">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="pageSizeChange"
      @current-change="pageNumChange"
      :current-page.sync="queryData.pageNum"
      :page-sizes="pageSizes"
      :page-size="queryData.pageSize"
      layout="sizes, prev, pager, next,jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { getPlatformQrCode } from "@/api/platform";
import {
  getLiveManagePage,
  editLiveRecommendStatus,
  deleteLive
} from "@/api/appManage";
export default {
  data() {
    return {
      list: [],
      total: 0,
      status: {
        101: "正在直播",
        102: "未开始",
        103: "已结束",
        104: "禁播",
        105: "暂停",
        106: "异常",
        107: "已过期"
      },
      pageSizes: [5, 10, 20, 30, 50],
      queryData: {
        pageNum: 1,
        pageSize: 5
      },
      cachedQrCode: {}, //缓存二维码
      curViewQrCode: "" //当前显示二维码图片
    };
  },
  created() {
    this.getLiveManagePage();
  },
  methods: {
    async delLive(id) {
      //删除直播
      const res = await deleteLive({ id });
      if (res) {
        this.$showSuccess("删除成功");
        this.getLiveManagePage();
      }
    },
    async changeLiveTop(id, status) {
      //推荐直播
      const res = await editLiveRecommendStatus({ id, status });
      if (res) {
        this.getLiveManagePage();
      }
    },
    async getQrCode(id, show) {
      //获取二维码
      if (!show) return;
      if (this.cachedQrCode[id]) {
        this.curViewQrCode = this.cachedQrCode[id];
        return;
      }
      this.curViewQrCode = "";
      const res = await getPlatformQrCode({ scene: `livePlay#${id}` });
      if (res) {
        this.curViewQrCode = res;
        this.cachedQrCode[id] = res;
      }
    },
    pageSizeChange(num) {
      this.queryData.pageSize = num;
      this.getLiveManagePage();
    },
    pageNumChange(num) {
      this.queryData.pageNum = num;
      this.getLiveManagePage();
    },
    async getLiveManagePage() {
      //获取列表
      const res = await getLiveManagePage(this.queryData);
      if (res) {
        this.list = res.list;
        this.total = res.total;
      }
    }
  }
};
</script>
<style lang="scss">
.dropdown-qrcode {
  padding: 30px 30px 20px 30px;
  .tips {
    line-height: 20px;
    text-align: center;
    color: $color9;
  }
  .img {
    width: 200px;
    height: 200px;
  }
}
</style>

<style scoped lang="scss">
.mlr10 {
  margin: auto 10px;
}
.qrcode-loading {
  font-size: 48px;
  color: #ccc;
  position: absolute;
  left: 37%;
  top: 60px;
}
.base-info {
  display: flex;
  .cover {
    width: 130px;
    height: 100px;
    border-radius: 5px;
  }
  .text {
    height: 100px;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .sub {
      color: $color9;
    }
  }
}
</style>
